import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import Title from "../component/Title";
import sanitizeHtml from "sanitize-html";
import Page404 from "../component/Page404";
import Loading from "../component/Loading/Loading";
import HoursItem from "../container/Hours/HoursItem";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { translate } from "react-translate";
import { withRouter } from "react-router-dom";
import { pageActions } from "react-adaptive-cms";
import { fixLinks, getPath, checkDomainAndPathName } from "../utils/Path";

import "./MapPageView.scss";

const mapStateToProps = state => ({
  page: state.page.page,
  hours: state.gjenvinningsstasjoner.records,
  hoursLoading: state.gjenvinningsstasjoner.loading,
  pageLoading: state.page.pageLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(Object.assign({}, pageActions), dispatch);

class MapPageView extends React.Component {
  componentDidMount() {
    const { loadPage, location } = this.props;
    const path = getPath(location);

    loadPage(path);
    if (location.hash) {
      const elem = document.getElementById(location.hash);
      if (elem) {
        elem.setAttribute("open", true);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      history,
      location,
      pageLoading,
      hours,
      t,
      match: {
        params: { params }
      }
    } = this.props;
    const prevPath = getPath(prevProps.location);
    const path = getPath(location);
    const hoursNode = document.getElementById("hours--item");

    fixLinks(history);
    if (path !== prevPath) {
      this.props.loadPage(path);
    }
    if (!!hoursNode) {
      ReactDOM.render(
        <HoursItem
          item={hours.find(item => item.uri.includes(params))}
          t={t}
        />,
        hoursNode
      );
    }

    if (prevProps.pageLoading && !pageLoading && location.hash) {
      const elem = document.getElementById(location.hash.replace("/", ""));
      if (elem && !elem.open) {
        elem.setAttribute("open", true);
        elem.scrollIntoView();
        window.scrollBy(0, -100);
      }
    }

    var summaryTags = document.querySelectorAll("details");
    summaryTags.forEach(
      f =>
        (f.onclick = () => {
          this.onSummaryClick(f);
        })
    );
  }

  onSummaryClick = item => {
    const {
      history,
      location: { pathname }
    } = this.props;

    if (item.id) {
      const hash = item.open ? "" : item.id;
      history.push(pathname + "/" + hash);
    }
    return true;
  };

  render() {
    const {
      page: { is404, content, title },
      pageLoading,
      hoursLoading,
      history
    } = this.props;
    return (
      <div className="map-page-view--page">
        <Title title={is404 ? "Page not found" : title || "Loading"} />
        {pageLoading && hoursLoading ? (
          <div className="loader">
            <Loading />
          </div>
        ) : is404 ? (
          <Page404 />
        ) : (
          <div
            className="map-page-view--cms"
            onClick={evt => {
              // capture clicks on links to current domain in order to use react router instead of full pageload.
              const targetLink = evt.target.closest("a");
              if (targetLink) {
                console.log("targetlink", targetLink);
                const { isCurrentDomain, pathname } = checkDomainAndPathName(
                  targetLink.href
                );
                if (isCurrentDomain) {
                  evt.preventDefault();
                  history.push(pathname);
                  window.scrollTo(0, 0);
                }
              }
            }}
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(
                (content || "").replace(/<style>[^\0]*?<\/style>/g, ""),
                {
                  allowedTags: sanitizeHtml.defaults.allowedTags.concat([
                    "details",
                    "summary",
                    "iframe",
                    "img",
                    "a"
                  ]),
                  allowedAttributes: {
                    "*": ["id", "class", "style", "align", "target"],
                    iframe: [
                      "width",
                      "height",
                      "src",
                      "name",
                      "title",
                      "scrolling",
                      "frameborder",
                      "allow",
                      "allowfullscreen"
                    ],
                    img: [
                      "src",
                      "srcset",
                      "alt",
                      "title",
                      "width",
                      "height",
                      "loading"
                    ],
                    a: ["class", "href", "target"]
                  },
                  allowedIframeDomains: ["youtube.com"]
                }
              )
            }}
          />
        )}
      </div>
    );
  }
}

MapPageView.propTypes = {
  t: PropTypes.func.isRequired,
  page: PropTypes.object,
  pageLoading: PropTypes.bool,
  loadPage: PropTypes.func,
  location: PropTypes.object,
  history: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(translate("MapPageView")(MapPageView)));
