import React from "react";
import "./NaeringLayout.scss";

class NaeringLayout extends React.Component {
  render() {
    const { children } = this.props;
    return <div className="naering-layout--root">{children}</div>;
  }
}

export default NaeringLayout;
