import React, { useMemo } from "react";
import sanitizeHtml from "sanitize-html";
import { Helmet } from "react-helmet";
import Loading from "../../component/Loading/Loading";
import Title from "../../component/Title";
import useDigiThemeData from "../../hooks/useDigiThemeData";
import { checkDomainAndPathName } from "../../utils/Path";
import { CategoryBadge } from "../../component/CategoryBadge";
import { withRouter } from "react-router";
import useNewsCategories from "../../hooks/useNewsCategories";

import config from "../../config";

import "./NewsSingleView.scss";

const NewsSingleView = ({
  match: {
    params: { id }
  },
  history
}) => {
  const aktueltDefinition = useMemo(() => {
    return {
      type: "aktuelt",
      filter: {
        filterColumns: [
          {
            name: "id",
            value: id
          }
        ]
      }
    };
  }, [id]);
  const [aktuelt, loadingAktuelt] = useDigiThemeData(aktueltDefinition);

  const [, getCategoryName] = useNewsCategories();

  const newsDefinition = useMemo(() => {
    return {
      type: "nyheter",
      filter: {
        filterColumns: [
          {
            name: "id",
            value: id
          }
        ]
      }
    };
  }, [id]);
  const [news, loadingNews] = useDigiThemeData(newsDefinition);

  const article = useMemo(
    () => (aktuelt.length > 0 ? aktuelt[0] : news.length > 0 ? news[0] : null),
    [aktuelt, news]
  );

  const loading = useMemo(
    () => loadingAktuelt || loadingNews,
    [loadingAktuelt, loadingNews]
  );

  return (
    <div className="news-single-view">
      {loading && !article ? (
        <Loading />
      ) : (
        <>
          {article && (
            <Helmet>
              <meta
                name="description"
                content={article.innhold
                  .replace(/<[^>]*>|\\n/g, "")
                  .slice(0, 150)}
              />
            </Helmet>
          )}
          <Title title={article.tittel} />
          <h1 className="news-single-view--title">{article.tittel}</h1>
          <div className="news-single-view--content">
            {article.bilde && (
              <div className="news-single-view--carousel">
                <img
                  src={
                    config.adaptiveUrl +
                    "WebServices/generic/media.asmx/Download?thumbnail_size=medium&uuid=" +
                    article.bilde
                  }
                  alt={article.tittel}
                />
              </div>
            )}
            <article className="news-single-view--article">
              {article.kategorier && article.kategorier.length > 0 && (
                <div className="news-single-view--categories-bar">
                  Publisert i kategorier:{" "}
                  {article.kategorier?.split(",").map(c => {
                    const categoryId = parseInt(c);
                    const categoryName = getCategoryName(categoryId);
                    if (!categoryName) {
                      return null;
                    } else {
                      return (
                        <CategoryBadge
                          key={categoryName}
                          label={categoryName}
                          path={`/sakersamla?kategori=${categoryName}`}
                        />
                      );
                    }
                  })}
                </div>
              )}
              <div
                onClick={evt => {
                  // capture clicks on links to current domain in order to use react router instead of full pageload.
                  const targetLink = evt.target.closest("a");
                  if (targetLink) {
                    // console.log("targetlink", targetLink);
                    const { isCurrentDomain, pathname } =
                      checkDomainAndPathName(targetLink.href);
                    if (isCurrentDomain) {
                      evt.preventDefault();
                      history.push(pathname);
                      window.scrollTo(0, 0);
                    }
                  }
                }}
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(article.innhold, {
                    allowedTags: sanitizeHtml.defaults.allowedTags.concat([
                      "details",
                      "summary",
                      "b",
                      "img",
                      "a",
                      "iframe",
                      "canvas"
                    ]),
                    allowedAttributes: {
                      "*": [
                        "id",
                        "href",
                        "target",
                        "class",
                        "style",
                        "align",
                        "width",
                        "height",
                        "data-view",
                        "src"
                      ]
                    },
                    allowedIframeDomains: ["adialog.no", "nordhordlandskart.no"]
                  })
                }}
              />
            </article>
          </div>
        </>
      )}
    </div>
  );
};
export default withRouter(NewsSingleView);
