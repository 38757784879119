import React from "react";
import PageLayout from "../layout/PageLayout";
import MapLayout from "../layout/MapLayout";
import PageView from "../view/PageView";
import WiderPageView from "../view/WiderPageView";
import MapPageView from "../view/MapPageView";
import IndexView from "../view/IndexView";
import HushaldView from "../view/HushaldView";
import NaeringLayout from "../layout/NaeringLayout";
import NaeringView from "../view/NaeringView";
import HytteView from "../view/HytteView";
import NewsAllView from "../container/News/NewsAllView";
import NewsSingleView from "../container/News/NewsSingleView";
import SkjemaView from "../view/SkjemaView";
import OmOss from "../component/SpecialPages/OmOss";
import Gjenvinningsstasjonar from "../component/SpecialPages/Gjenvinningsstasjonar";
import Tenester from "../component/SpecialPages/Tenester";
import Naering from "../component/SpecialPages/Naering";
import Nettstedkart from "../component/SpecialPages/Nettstedkart";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

class AppRouter extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/tenester">
            <MapLayout>
              <Route component={Tenester} />
              <Route component={MapPageView} />
            </MapLayout>
          </Route>
          <Route exact path="/nettstedkart">
            <PageLayout>
              <OmOss>
                <Route path="/nettstedkart" component={Nettstedkart} />
              </OmOss>
            </PageLayout>
          </Route>
          <Route exact path="/omoss">
            <MapLayout>
              <OmOss>
                <Route path="/omoss" component={MapPageView} />
              </OmOss>
            </MapLayout>
          </Route>
          <Route exact path="/gjenvinningsstasjonar/:params">
            <MapLayout>
              <Route component={Gjenvinningsstasjonar} />
              <Route component={MapPageView} />
            </MapLayout>
          </Route>
          <Route path="/naering">
            <NaeringLayout>
              <Switch>
                <Route exact path="/naering/tenester">
                  <MapLayout>
                    <Route component={Naering} />
                    <Route component={MapPageView} />
                  </MapLayout>
                </Route>
                <Route path="/naering">
                  <PageLayout>
                    <Route component={Naering} />
                    <Route path="/naering/:params" component={PageView} />
                    <Route exact path="/naering" component={NaeringView} />
                  </PageLayout>
                </Route>
              </Switch>
            </NaeringLayout>
          </Route>
          {/* <Route path="/hushald">
            <HushaldLayout> */}
          {/* <Switch> */}
          <Route path="/hushald/adresse">
            <MapLayout>
              <Route path="/hushald/adresse" component={HushaldView} />
            </MapLayout>
          </Route>
          {/* <Route path="/:params">
                  <PageLayout>
                    <Route exact path="/:params" component={PageView} />
                  </PageLayout>
                </Route> */}
          {/* </Switch> */}
          {/* </HushaldLayout>
          </Route> */}
          <Route path="/hushald/:params">
            <PageLayout>
              <Route component={PageView} />
            </PageLayout>
          </Route>

          {/* <Route path="/hushald">
            <HushaldLayout>
              <Switch>
                <Route path="/hushald/adresse">
                  <MapLayout>
                    <Route path="/hushald/adresse" component={HushaldView} />
                  </MapLayout>
                </Route>
                <Route path="/:params">
                  <PageLayout>
                    <Route exact path="/:params" component={PageView} />
                  </PageLayout>
                </Route>
              </Switch>
            </HushaldLayout>
          </Route> */}
          <Route path="/hytte/adresse">
            <MapLayout>
              <Route path="/hytte/adresse" component={HytteView} />
            </MapLayout>
          </Route>
          {/* <Route path="/hytte">
            <HushaldLayout>
              <Switch>
                <Route path="/hytte/adresse">
                  <MapLayout>
                    <Route path="/hytte/adresse" component={HytteView} />
                  </MapLayout>
                </Route>
                <Route path="/:params">
                  <PageLayout>
                    <Route exact path="/:params" component={PageView} />
                  </PageLayout>
                </Route>
              </Switch>
            </HushaldLayout>
          </Route> */}
          <Route path="/hytte/:params">
            <PageLayout>
              <Route component={PageView} />
            </PageLayout>
          </Route>
          <Route path="/sak">
            <PageLayout>
              <Route exact path="/sak/:id([0-9]+)" component={NewsSingleView} />
            </PageLayout>
          </Route>
          <Route path="/sakersamla">
            <PageLayout>
              <Route exact path="/sakersamla" component={NewsAllView} />
            </PageLayout>
          </Route>

          {/* Redirects */}
          <Route exact path="/NGIR-kort">
            <Redirect to="/adgangsbevis" />
          </Route>
          <Route exact path="/leige-container">
            <Redirect to="/naering/leige-container" />
          </Route>
          <Route exact path="/steinsandoggrus">
            <Redirect to="/naering/steinsandoggrus" />
          </Route>
          <Route exact path="/mellombelsavfallsmottak">
            <Redirect to="/mobilgjenvinningsstasjon" />
          </Route>
          <Route path="/NGIR-kort/om-NGIR-kortet">
            <PageLayout>
              <Redirect to="/adgangsbevis/ngir-kort" />
            </PageLayout>
          </Route>
          <Route path="/NGIR-kort/dele-app">
            <PageLayout>
              <Redirect to="/adgangsbevis/dele-tilgong" />
            </PageLayout>
          </Route>
          <Route path="/NGIR-kort/om-miljoid">
            <PageLayout>
              <Redirect to="/adgangsbevis/miljoid-app" />
            </PageLayout>
          </Route>
          <Route path="/NGIR-kort/:params">
            <PageLayout>
              <Redirect to="/adgangsbevis/:params" />
            </PageLayout>
          </Route>

          <Route exact path="/batvrak">
            <PageLayout>
              <Redirect to="/levere-batvrak" />
            </PageLayout>
          </Route>
          <Route exact path="/bilvrak">
            <PageLayout>
              <Redirect to="/levere-bilvrak" />
            </PageLayout>
          </Route>
          <Route exact path="/leigekonteinar">
            <PageLayout>
              <Redirect to="/leige-container" />
            </PageLayout>
          </Route>
          <Route exact path="/oftestiltesporsmal">
            <PageLayout>
              <Redirect to="/ofte-stilte-sporsmal" />
            </PageLayout>
          </Route>
          <Route
            exact
            path="/oftestiltesporsmal/onskjer-betalingsavtale-e-faktura-avtalegiro-skal-opphøyra"
          >
            <PageLayout>
              <Redirect to="/ofte-stilte-sporsmal/onskjer-betalingsavtale-e-faktura-avtalegiro-skal-opphoyra" />
            </PageLayout>
          </Route>
          <Route exact path="/hushald/kompoststro">
            <PageLayout>
              <Redirect to="/hushald/kompostering" />
            </PageLayout>
          </Route>
          <Route exact path="/slik-sorterer-du/EE-avfall">
            <PageLayout>
              <Redirect to="/slik-sorterer-du/elektrisk-elektronisk-avfall" />
            </PageLayout>
          </Route>
          <Route exact path="/slik-sorterer-du/tekstilar">
            <PageLayout>
              <Redirect to="/slik-sorterer-du/tekstil" />
            </PageLayout>
          </Route>

          {/* End of redirects */}
          <Route path="/adgangsbevis/:params">
            <PageLayout>
              <Route component={PageView} />
            </PageLayout>
          </Route>
          <Route path="/ofte-stilte-sporsmal/:params">
            <PageLayout>
              <Route component={PageView} />
            </PageLayout>
          </Route>
          <Route path="/mottakskrav/:params">
            <PageLayout>
              <Route component={PageView} />
            </PageLayout>
          </Route>
          <Route path="/strandryddingogskulebesok/:params">
            <PageLayout>
              <Route component={PageView} />
            </PageLayout>
          </Route>
          <Route path="/tommekalender/:params">
            <PageLayout>
              <Route component={PageView} />
            </PageLayout>
          </Route>
          <Route path="/strandryddingogskulebesøk/:params">
            <PageLayout>
              <Route component={PageView} />
            </PageLayout>
          </Route>

          <Route path="/slik-sorterer-du/:params">
            <PageLayout>
              <Route component={PageView} />
            </PageLayout>
          </Route>

          <Route path="/">
            <PageLayout>
              <Switch>
                <Route
                  exact
                  path="/meldingsteneste"
                  component={WiderPageView}
                />
                <Route path="/skjema" component={SkjemaView} />
                <Route exact path="/" component={IndexView} />
                <Route exact path="/index">
                  <Redirect to="/" />
                </Route>
                <Route exact path="/:params" component={PageView} />
              </Switch>
            </PageLayout>
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default AppRouter;
