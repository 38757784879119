import React, { useCallback, useState, useMemo } from "react";
import request from "superagent";
import RadioButtongGroup from "../../component/RadioButtonGroup";
import Loading from "../../component/Loading/Loading";
import Config from "../../config/index";
import {
  DigiThemeManager,
  DigiThemeLoader,
  DateField,
  Field
} from "react-adaptive-dt-form";
import { withRouter } from "react-router-dom";
import { translate } from "react-translate";
import Error from "./Error";
import Success from "./Success";
import { isInvalid, toRecord } from "../../utils/record";
import "./Skjema.scss";
import Attachments from "./Attachments";

import "react-adaptive-dt-form/lib/style.scss";

const Slamtomming = ({ history, t }) => {
  const [saving, setSaving] = useState();
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [formState, setFormState] = useState({});
  const [id, setId] = useState();
  const isRecordInvalid = isInvalid([formState]);
  const [attachments, setAttachments] = useState([]);

  const handleField = useCallback(
    props => {
      const { name } = props;
      const { eigedomstype } = formState;

      switch (name) {
        case "birthdate":
          return (
            <Field {...props}>
              <DateField
                {...props}
                minDate={new Date(1900, 0, 1)}
                maxDate={new Date()}
              />
            </Field>
          );

        case "eigedomstype":
          return <RadioButtongGroup {...props} />;

        case "grunngjeving_for_soknaden":
          if (eigedomstype && eigedomstype.value) {
            const items = props.items.filter(i => {
              if (eigedomstype.value === "Bustad") {
                return true;
              } else if (eigedomstype.value === "Fritidsbustad") {
                if (i.indexOf("Bustaden/Fritidsbustaden") > -1) {
                  return true;
                }
                return false;
              }
              return false;
            });
            return <RadioButtongGroup {...props} items={items} />;
          } else {
            return false;
          }

        case "id":
        case "behandlet":
        case "date_created":
          return false;

        default:
          return;
      }
    },
    [formState]
  );

  const save = () => {
    setSaving(true);
    request
      .post(Config.adaptiveUrl + "WebServices/client/DataView.asmx/SaveAny")
      .send({
        request: {
          theme_uuid: Config.forms.slamtommingSkjema.uuid,
          data: toRecord(formState),
          mediaUuids: attachments
        }
      })
      .set("Content-Type", "application/json")
      .then(res => res.body)
      .then(res => {
        if (!res.d.success) {
          console.error("Could not save project", res.d);
          setSaving(false);
          setError(true);
          return;
        }
        setSaving(false);
        setSuccess(true);
        setId(res && res.d.data.length > 0 && res.d.data[0].value.id);
      })
      .catch(e => {
        console.error("Could not save project", e);
        setSaving(false);
        setError(true);
      });
    setSaving(false);
  };

  const onAttachmentAdded = useCallback(
    uuid => {
      setAttachments(a => [...a, uuid]);
    },
    [setAttachments]
  );

  const onAttachmentRemoved = useCallback(
    uuid => {
      setAttachments(att => [att.filter(a => a !== uuid)]);
    },
    [setAttachments]
  );

  const attachmentsValid = useMemo(() => {
    if (
      formState.grunngjeving_for_soknaden?.value ===
      "Bustaden har eigen utleigedel som skal nyttast til eige bruk."
    ) {
      return true;
    }
    return !!attachments ? attachments.length > 0 : false;
  }, [attachments, formState]);

  return (
    <>
      <h1>{t("soknadDispSlam")}</h1>
      {!success && !id && (
        <p className="skjema-header">
          {t("required")} <br />
          <span>{t("sensitive")}</span>
        </p>
      )}
      {!saving && (!success || error) && (
        <DigiThemeManager key="dt-manager" adaptiveUrl={Config.adaptiveUrl}>
          <DigiThemeLoader
            key="dt-loader"
            adaptiveUrl={Config.adaptiveUrl}
            themeUuid={Config.forms.slamtommingSkjema.uuid}
            handleField={handleField}
            onChange={(name, value, invalid, dirty) => {
              if (name === "eigedomstype") {
                setFormState(d => {
                  return {
                    ...d,
                    [name]: { value, invalid, dirty }
                  };
                });
                return;
              }

              if (name === "birthdate") {
                setFormState(d => {
                  return {
                    ...d,
                    [name]: {
                      value: value ? new Date(value) : undefined,
                      invalid,
                      dirty
                    }
                  };
                });
                return;
              }
              setFormState(d => {
                return { ...d, [name]: { value, invalid, dirty } };
              });
            }}
            formState={formState}
            lang={{
              dateFormat: "dd.MM.yyyy",
              dateFormatPlaceholder: "DD.MM.YYYY",
              months: [
                "Januar",
                "Februar",
                "Mars",
                "April",
                "Mai",
                "Juni",
                "Juli",
                "August",
                "September",
                "Oktober",
                "November",
                "Desember"
              ],
              weekdaysShort: ["Man", "Tir", "Ons", "Tor", "Fre", "Lør", "Søn"],
              weekdaysLong: [
                "Mandag",
                "Tirsdag",
                "Onsdag",
                "Torsdag",
                "Fredag",
                "Lørdag",
                "Søndag"
              ],
              clear: t("clear"),
              today: t("today")
            }}
          />
          <Attachments
            themeUuid={Config.forms.slamtommingSkjema.uuid}
            onAttachmentAdded={onAttachmentAdded}
            onAttachmentRemoved={onAttachmentRemoved}
            attachments={attachments}
            label={t("labelVedleggSlam")}
          />
          <button
            className={["btn", "btn-secondary", "dt-button"].join(" ")}
            type="button"
            onClick={() => history.push("/")}
          >
            {t("cancel")}
          </button>
          <button
            disabled={isRecordInvalid || !attachmentsValid || saving}
            className={["btn", "dt-button"].join(" ")}
            type="button"
            onClick={() => save()}
          >
            {t("save")}
          </button>
        </DigiThemeManager>
      )}
      {!saving && error && (
        <Error key="from-error" onClose={() => setError(false)} />
      )}
      {!saving && success && <Success idx={id} key="form-success" />}
      {saving && (
        <div key="form-loader" className="loader">
          <Loading />
        </div>
      )}
    </>
  );
};

export default translate("Skjema")(withRouter(Slamtomming));
