const isInvalid = records => {
  if (!Array.isArray(records)) {
    records = [records];
  }

  return records.some(record => {
    return Object.keys(record).some(name => {
      return record[name].invalid && record[name].invalid === true;
    });
  });
};

const isDirty = records => {
  if (!Array.isArray(records)) {
    records = [records];
  }

  return records.some(record => {
    return Object.keys(record).some(name => {
      return record[name].dirty && record[name].dirty === true;
    });
  });
};

const toRecord = fields => {
  return Object.keys(fields).reduce((record, name) => {
    return { ...record, [name]: fields[name].value };
  }, {});
};

export { isInvalid, isDirty, toRecord };
