import React from "react";
import Nav from "./Nav";
import Hours from "../Hours/Hours";
import Logo from "../../static/ngir_logo.svg";
import { Link } from "react-router-dom";
import { translate } from "react-translate";
import { useMediaQuery } from "react-responsive";
import TextSearch from "../../component/TextSearch/TextSearch";
import "./NavBar.scss";

const NavBar = ({ nav, t }) => {
  const isHandheld = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <div className="nav-bar--root">
      <div className="nav-bar--root-container">
        <div className="nav-bar--top-row">
          <div className="nav-bar--logo">
            <Link className="nav-bar--logo-link" to="/">
              <img
                className="nav-bar--logo"
                src={Logo}
                alt="NGIR - Renovasjon i Nordhordland, Gulen og Solund"
              />
            </Link>
            <p className="nav-bar--header">{t("header")}</p>
          </div>
          <div className="nav-bar--top-row-buttons">
            <TextSearch></TextSearch>
            {!isHandheld && (
              <Link className="btn btn-warning" to="/meldingsteneste">
                {t("avvik").toUpperCase()}
              </Link>
            )}

            <Nav nav={nav} />
          </div>
        </div>

        <div className="nav-bar--button-group ">
          {!isHandheld && (
            <div className="nav-bar--button-group__links">
              <Link to="/gjenvinningsstasjonar">
                {t("gjenvinningsstasjonar")}
              </Link>
              <Hours />
              <Link to="/naering">{t("naering")}</Link>
            </div>
          )}

          {isHandheld && (
            <div className="nav-bar--button-group__links">
              <Link to="/gjenvinningsstasjonar">
                {t("gjenvinningsstasjonar")}
              </Link>
              <Hours />
              {/* <Link to="/naering">{t("naering")}</Link> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default translate("NavBar")(NavBar);
