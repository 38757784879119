import React from "react";
import "./Loading.scss";

export default () => (
  <>
    <div className="loading--overlay" />
    <div className="loading--dots">
      <span />
      <span />
      <span />
    </div>
  </>
);
