import React from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";
import "./Modal.scss";

class Modal extends React.PureComponent {
  componentWillMount() {
    document.body.classList.add("modal-open");
  }

  componentWillUnmount() {
    document.body.classList.remove("modal-open");
  }

  render() {
    const { className, onClose } = this.props;
    return (
      <div className={["modal--overlay", className].join(" ")}>
        <div className="modal--container">
          {onClose && (
            <div className="modal--button-bar">
              <button className="close-btn" type="button" onClick={onClose}>
                <Icon name="cross" />
              </button>
            </div>
          )}
          <div className="modal--content">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func
};

export default Modal;
