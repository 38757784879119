import React from "react";
import PropTypes from "prop-types";
import NavBar from "../container/NavBar/NavBar";
import Footer from "../container/Footer/Footer";
import { MapPadding } from "@avinet/react-openlayers";
import { provideViewSize } from "react-adaptive-utils";
import "./MapLayout.scss";

class MapLayout extends React.Component {
  static propTypes = {
    viewWidth: PropTypes.number.isRequired,
    viewHeight: PropTypes.number.isRequired,
    children: PropTypes.node
  };

  calcMapPadding() {
    const { viewHeight, viewWidth } = this.props;
    if (viewWidth < 1024) {
      return [0, 0, viewHeight / 2, 0];
    }
    return [0, viewWidth / 2, 0, 0];
  }
  render() {
    const { children } = this.props;

    return (
      <div className="map-layout--root">
        <MapPadding padding={this.calcMapPadding()} />
        <NavBar />
        <div className="map-layout--content">
          {children}
          <Footer />
        </div>
      </div>
    );
  }
}

export default provideViewSize(MapLayout);
