import { useState, useEffect, useCallback } from "react";
import request from "superagent";

import config from "../config/index";

const readAnyUrl =
  config.adaptiveUrl + "WebServices/client/DataView.asmx/ReadAny";

const cache = {};

export default function useDigiThemeData({
  type,
  filter,
  useCache = false,
  limit = 0
}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const load = useCallback(async () => {
    setData([]);

    if (type in config.themes) {
      if (useCache && type in cache) {
        setData(cache[type]);
        setLoading(false);
        return;
      }

      const req = request
        .post(readAnyUrl + "?item=" + type)
        .set({
          "X-Adaptive-SRID": config.mapProjCode.toString()
        })
        .send({
          request: {
            theme_uuid: config.themes[type].uuid,
            columns: config.themes[type].columns,
            filter,
            start: 0,
            limit
          }
        });

      await req
        .then(res => res.body.d)
        .then(res => {
          if (!res.success) {
            console.error("Request response unsuccessful", res);
            setData([]);
            setLoading(false);
            return;
          }
          const records = res.records;

          if (!(type in cache)) {
            cache[type] = {};
          }
          cache[type] = records;

          setData(records);
          setLoading(false);
        })
        .catch(err => {
          if (err.code && err.code === "ABORTED") return;
          setData([]);
          setLoading(false);
          console.warn("Request failed", err);
        });
      return () => req.abort();
    }
  }, [type, useCache, limit, filter]);

  useEffect(() => {
    load();
  }, [load]);

  return [data, loading];
}
