import React, { useState, useCallback } from "react";
import Icon from "../Icon";
import { translate } from "react-translate";
import { withRouter } from "react-router-dom";
import {
  SearchField,
  SearchAgent,
  internalSearch
} from "@avinet/react-adaptive-search";

import "./Search.scss";

const Search = ({ history, t }) => {
  const [source, setSource] = useState("");
  const [item, setItem] = useState();

  const onSearchItemSelect = useCallback(
    (type, item) => {
      switch (type) {
        case "hushald":
          history.push("/hushald/adresse", {
            address: {
              id: item.uid,
              title: item.title,
              desc: item.desc,
              geom: item.geom
            }
          });
          break;

        case "hytteeigar":
          history.push("/hytte/adresse", {
            address: {
              id: item.uid,
              title: item.title,
              desc: item.desc,
              geom: item.geom
            }
          });
          break;

        default:
          break;
      }
    },
    [history]
  );

  const onSubmit = useCallback(
    e => {
      e.preventDefault();

      if (!(item && source)) return;
      onSearchItemSelect(source, item);
    },
    [item, onSearchItemSelect, source]
  );

  return (
    <div className="search">
      <h2 className="search--title">{t("title")}</h2>
      <h2 className="search--subtitle">{t("subtitle")}</h2>
      <SearchField
        canClearValue
        placeholder={t("addressSearchPlaceholder")}
        onItemSelect={item => setItem(item)}
        required
      >
        <>
          <div className="search-field--field__options">
            <label htmlFor="search-field--source">&nbsp;</label>
            <select
              id="search-field--source"
              title="Search parameter"
              value={source}
              onChange={e => setSource(e.target.value)}
              required
            >
              <option value="" disabled>
                Velg type
              </option>
              <option value="hushald">hushald</option>
              <option value="hytteeigar">hytteeigar</option>
            </select>
            <button type="submit" title="Search" onClick={onSubmit}>
              <Icon name="search" />
            </button>
          </div>
          <SearchAgent
            minItems={10}
            title={t("addresses")}
            searchFunction={internalSearch({ layers: "AdaptiveAddresses" })}
          />
        </>
      </SearchField>
    </div>
  );
};

export default withRouter(translate("Search")(Search));
