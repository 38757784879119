import React, { useMemo } from "react";
import Card from "./Card";
import Loading from "../../component/Loading/Loading";
import useDigiThemeData from "../../hooks/useDigiThemeData";
import { Link } from "react-router-dom";
import { translate } from "react-translate";

import "./News.scss";

const News = ({ t }) => {
  const aktueltDefinition = useMemo(() => {
    return {
      type: "aktuelt",
      filter: {
        filterColumns: [
          {
            name: "aktiv",
            value: true
          }
        ],
        sortColumns: [{ name: "prioritet", sortDesc: "false" }]
      },
      limit: 4
    };
  }, []);
  const [aktuelt, loadingAktuelt] = useDigiThemeData(aktueltDefinition);

  return (
    <div className="news">
      <h2 className="news--title">{t("title")}</h2>
      <div className="news--content">
        {loadingAktuelt && !aktuelt ? (
          <Loading />
        ) : (
          aktuelt.map((record, i) => (
            <Card
              key={i}
              id={record.id}
              title={record.tittel}
              description={record.innhold}
              imgSrc={record.bilde}
            />
          ))
        )}
      </div>
      {aktuelt.length >= 4 && (
        <Link className="news--link" to="/sakersamla">
          {t("seeAll")}
        </Link>
      )}
    </div>
  );
};

export default translate("News")(News);
