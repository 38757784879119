import React, { useState, useCallback, useRef } from "react";
import request from "superagent";
import config from "../../config";
import Icon from "../Icon";
import { withRouter } from "react-router-dom";
import useClickedOutside from "../../hooks/useClickedOutside";
import "./TextSearch.scss";

const TextSearch = props => {
  const { history } = props;
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchExpression, setSearchExpression] = useState("");
  const [emptyResultsText, setEmptyResultsText] = useState("");
  const ref = useRef();
  const handleClick = useCallback(
    evt => {
      setExpanded(!expanded);
    },
    [expanded, setExpanded]
  );

  useClickedOutside(ref, handleClick);

  const navigateTo = useCallback(
    path => {
      console.log(path);
      history.push(path);
      setExpanded(!expanded);
    },
    [history, expanded, setExpanded]
  );

  const performSearch = useCallback(() => {
    console.log("Searching elastic for: ", searchExpression);
    setLoading(true);
    setSearchResults([]);

    var query = {
      query: {
        bool: {
          must: [
            {
              match: {
                domain: {
                  query: config.search.domain
                }
              }
            },
            {
              multi_match: {
                query: searchExpression,
                fields: ["title^3", "h1^3", "h2^2", "h3^2", "p", "li"]
              }
            }
          ]
        }
      }
    };

    request
      .post(`${config.search.searchUrl}`)
      .send({
        query,
        index: config.search.index,
        domain: config.search.domain
      })
      .set("Content-Type", "application/json")
      .then(res => res.body)
      .then(res => {
        console.log("res, ", res);
        if (
          res.success &&
          res.data.hits &&
          res.data.hits.hits &&
          res.data.hits.hits.length > 0
        ) {
          setEmptyResultsText("");
          setSearchResults(res.data.hits.hits);
        } else {
          setEmptyResultsText(
            `Fant ingen resultater for "${searchExpression}"`
          );
          setSearchResults([]);
        }
        setLoading(false);
      })
      .catch(e => {
        console.error("Could not query elasticsearch", e);
        setLoading(false);
      });
  }, [searchExpression]);

  return (
    <div className="textsearch--container">
      <button
        type="button"
        className="textsearch--button"
        onClick={() => setExpanded(!expanded)}
      >
        <Icon name="search" />
      </button>
      {expanded && (
        <div ref={ref} className="textsearch--content-container">
          <div className="textsearch--input-container">
            <input
              onChange={e => setSearchExpression(e.target.value)}
              onKeyPress={e => {
                if (e.key === "Enter") {
                  performSearch();
                }
              }}
              autoFocus
              type="text"
              placeholder="Søk etter innhold på ngir.no"
            ></input>
            <button type="button" onClick={() => performSearch()}>
              <Icon name="search" />
            </button>
            {/* {searchResults && searchResults.length > 0 && (
              <div
                className="textsearch--remove-search-icon"
                onClick={evt => {
                  evt.preventDefault();
                  setSearchResults([]);
                }}
              >
                Fjern søkeresultater
                <div className="textsearch--remove-search-icon-wrapper">
                  <Icon name="cross" />
                </div>
              </div>
            )} */}
          </div>
          {loading && (
            <div className="textsearch--loading-indicator">Søker ...</div>
          )}
          {!loading && emptyResultsText && (
            <div className="textsearch--loading-indicator">
              {emptyResultsText}
            </div>
          )}
          {searchResults.map(sr => (
            <div
              key={sr._id}
              onClick={() => navigateTo(new URL(sr._source.url).pathname)}
              className="textsearch--search-result-row"
            >
              <div className="textsearch--search-result-title">
                {sr._source.h1}
              </div>
              <div className="textsearch--search-result-content">
                {sr._source.p.slice(0, 250)}
                {sr._source.p.length > 250 ? "..." : ""}
              </div>
              <div className="textsearch--search-result-url">
                {sr._source.url}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default withRouter(TextSearch);
