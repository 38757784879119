import React from "react";
import { Link } from "react-router-dom";
import "./CategoryBadge.scss";

export const CategoryBadge = ({ label, path }) => {
  return (
    <div className="category-badge">
      <Link to={path}>{label}</Link>
    </div>
  );
};
