import React from "react";
import sanitizeHtml from "sanitize-html";

import { withRouter } from "react-router-dom";
import { translate } from "react-translate";

const Success = ({ history, t, idx }) => {
  return (
    <div className="skjema--success">
      <h2>{t("successHeader")}</h2>
      <div
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(t("successMsg").replace("{0}", idx))
        }}
      />
      <button
        className="message--button success"
        type="button"
        onClick={() => history.push("/")}
      >
        {t("toHomePage")}
      </button>
    </div>
  );
};

export default translate("Skjema")(withRouter(Success));
