import React, { useMemo, useEffect, useState } from "react";
import { translate } from "react-translate";
import Card from "./Card";
import Loading from "../../component/Loading/Loading";
import useDigiThemeData from "../../hooks/useDigiThemeData";
import { CategoryBadge } from "../../component/CategoryBadge";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { fixDate } from "../../utils/date";

import "./NewsAllView.scss";
import useNewsCategories from "../../hooks/useNewsCategories";

const NewsAllView = ({ t, location }) => {
  const [currentCategory, setCurrentCategory] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState(null);

  const aktueltDefinition = useMemo(() => {
    return {
      type: "aktuelt",
      filter: {
        filterColumns: [
          {
            name: "aktiv",
            value: true
          }
        ],
        sortColumns: [{ name: "prioritet" || "id", sortDesc: "true" }]
      }
    };
  }, []);
  const [aktuelt, loadingAktuelt] = useDigiThemeData(aktueltDefinition);

  const newsDefinition = useMemo(() => {
    return {
      type: "nyheter",
      filter: {
        filterColumns: [
          {
            name: "aktiv",
            value: true
          }
        ],
        sortColumns: [{ name: "id", sortDesc: "true" }]
      }
    };
  }, []);
  const [news, loadingNews] = useDigiThemeData(newsDefinition);

  const [mappedCategories] = useNewsCategories();

  const data = useMemo(() => {
    let data = [];
    if (!!(aktuelt.length && news.length)) {
      if (!categoryFilter) {
        //categoryfilter only works on aktuelt-items
        data = aktuelt.concat(news);
      } else {
        data = aktuelt;
      }

      data = data
        .map(n => {
          n.parsedDate = fixDate(n.date_created);
          return n;
        })
        .sort((a, b) => {
          return b.parsedDate - a.parsedDate;
        });

      if (categoryFilter) {
        data = data.filter(d => {
          if (!d.kategorier) {
            return false;
          }
          var index = d.kategorier.split(",").findIndex(i => {
            return i === categoryFilter.toString();
          });
          return index > -1;
        });
      }
    }
    return data;
  }, [aktuelt, news, categoryFilter]);

  useEffect(() => {
    var searchParams = new URLSearchParams(location.search);
    if (searchParams.has("kategori")) {
      const c = searchParams.get("kategori");
      setCurrentCategory(c);
    } else {
      setCurrentCategory(null);
      setCategoryFilter(null);
    }
  }, [location.search]);

  useEffect(() => {
    if (currentCategory && mappedCategories) {
      for (var i = 0; i < Object.keys(mappedCategories).length; i++) {
        const categoryValue =
          mappedCategories[Object.keys(mappedCategories)[i]];
        const categoryKey = Object.keys(mappedCategories)[i];
        if (categoryValue === currentCategory) {
          setCategoryFilter(parseInt(categoryKey));
        }
      }
    }
  }, [currentCategory, mappedCategories]);

  return (
    <div className="newsAll">
      <h2 className="newsAll--title">{t("title")}</h2>
      {categoryFilter && (
        <div className="newsAll--category-row">
          Viser saker publisert med kategori{" "}
          <CategoryBadge
            label={currentCategory}
            path={`/sakersamla?kategori=${currentCategory}`}
          />
          <div className="remove-filter-text">
            <Link to="/sakersamla">(Fjern filter)</Link>
          </div>
        </div>
      )}
      <div className="newsAll--content">
        {(loadingNews || loadingAktuelt) && !data ? (
          <Loading />
        ) : (
          data.map((record, i) => (
            <Card
              key={i}
              id={record.id}
              title={record.tittel}
              description={record.innhold}
              imgSrc={record.bilde}
            />
          ))
        )}
      </div>
      {/* {data.length >= 12 && (
 pagination
      )} */}
    </div>
  );
};

export default translate("NewsAllView")(withRouter(NewsAllView));
