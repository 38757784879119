import React from "react";
import Modal from "../../component/Modal";
import { translate } from "react-translate";

const Error = ({ onClose, t }) => {
  return (
    <Modal className="save-failure">
      <h2>{t("errorHeader")}</h2>
      <p>{t("errorMsg")}</p>
      <button
        className="message--button failure"
        type="button"
        onClick={onClose}
      >
        {t("ok")}
      </button>
    </Modal>
  );
};

export default translate("Skjema")(Error);
