import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import { RadioGroup, Radio } from "@blueprintjs/core";

RadioButtongGroup.propTypes = {
  /**
   * Field value
   */
  value: PropTypes.string,
  /**
   * Triggered when field value is changed
   */
  onValueChange: PropTypes.func,
  /**
   * Set to true to and control should appear as an inline element.
   */
  inline: PropTypes.bool,
  /**
   * Set to true to disable this field
   */
  disabled: PropTypes.bool,
  /**
   * List of availabe choices
   */
  items: PropTypes.array,
  name: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  onValidityChange: PropTypes.func
};

export default function RadioButtongGroup({
  name,
  inline,
  disabled,
  required,
  label,
  items,
  value,
  onValueChange,
  onValidityChange
}) {
  const [valid, setValid] = useState(true);

  useEffect(() => {
    const invalidRequired = !value && required;

    const isValid = !invalidRequired;
    if (isValid !== valid) {
      setValid(isValid);
      onValidityChange && onValidityChange(isValid);
    }
  }, [value, valid, required, onValidityChange]);

  const handleChange = e => {
    let { value: elementValue } = e.target;
    onValueChange(elementValue || null);
  };

  return (
    <RadioGroup
      name={name}
      label={required ? `${label} (*)` : label}
      onChange={handleChange}
      value={name}
      selectedValue={value || ""}
      disabled={disabled}
      inline={inline}
      className="dt-field bp3-form-group"
    >
      {items.map(item => (
        <Radio label={item.trim()} key={item} value={item.trim()} />
      ))}
    </RadioGroup>
  );
}
