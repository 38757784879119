import React, { useEffect } from "react";

import "./Sortere.scss";

const URL_PRIVAT =
  "https://sortere.no/sokeminiprivat/JTdCJTIydGl0bGUlMjIlM0ElMjJLdmElMjB2aWwlMjBkdSUyMGxldmVyYSUzRiUyMiUyQyUyMnBsYWNlaG9sZGVyJTIyJTNBJTIyU2tyaXYlMjBpbm4lMjBrdmElMjBkdSUyMHZpbCUyMHNvcnRlcmElMkZsZXZlcmElMjAodC5kLiUyMHR5cGUlMjBhdmZhbGwpJTIyJTdE";
const URL_NAERING =
  "https://sortere.no/sokemini/JTdCJTIydGl0bGUlMjI6JTIySHZhJTIwdmlsJTIwZGVyZSUyMHNvcnRlcmUlMjBwJUMzJUE1JTIwam9iYj8lMjIsJTIycGxhY2Vob2xkZXIlMjI6JTIyRi5la3MuJTIwYm9ibGVwbGFzdCwlMjBmbGlzZXIsJTIwYmx5YmF0dGVyaSUyMGVsbGVyJTIwZ3JhbnVsYXQuJTIyJTdE";

function loadSript(src) {
  var exists = document.querySelector("#sortere");
  if (exists) {
    exists.parentNode.removeChild(exists);
  }

  var tag = document.createElement("script");
  tag.id = "sortere";
  tag.async = false;
  tag.src = src;
  document.getElementsByTagName("body")[0].appendChild(tag);
}

const Sortere = checkIfNaering => {
  useEffect(() => {
    console.log(
      "loading script: ",
      checkIfNaering.isNaering(),
      checkIfNaering.isNaering() ? URL_NAERING : URL_PRIVAT
    );
    loadSript(checkIfNaering.isNaering() ? URL_NAERING : URL_PRIVAT);
  }, [checkIfNaering]);

  return (
    <div className="sortere-private-search">
      <div
        id={
          checkIfNaering?.isNaering()
            ? "mini-sortere-search"
            : "mini-sortere-private-search"
        }
      />
      <p>
        Last ned Sortere-appen frå{" "}
        <a
          href="https://play.google.com/store/apps/details?id=com.sortere"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Play
        </a>{" "}
        eller{" "}
        <a
          href="https://apps.apple.com/no/app/id1519252225"
          target="_blank"
          rel="noopener noreferrer"
        >
          App Store
        </a>
      </p>
    </div>
  );
};

export default Sortere;
