import React from "react";
import PropTypes from "prop-types";
import WKT from "ol/format/WKT";
import Collapsible from "../../component/Collapsible";
import Config from "../../config";
import { translate } from "react-translate";
import {
  WmsLayer,
  VectorSource,
  provideMapState
} from "@avinet/react-openlayers";
import { Style, Fill, Stroke } from "ol/style";
import { connect } from "react-redux";
import {
  scopedBindActionCreators,
  digiThemeActions
} from "react-adaptive-utils";
import { Link } from "react-router-dom";
import "./AvvikDropdown.scss";

const mapStateToProps = state => ({
  records: state.hentedag.records,
  loading: state.hentedag.loading
});

const mapDispatchToProps = dispatch =>
  scopedBindActionCreators(
    Object.assign({}, digiThemeActions),
    dispatch,
    "hentedag"
  );

class AvvikDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  static propTypes = {
    address: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.readHentedag();
  }

  readHentedag() {
    const { address } = this.props;

    const filter = Config.themes.hentedag.intersectFilter;
    filter.value =
      "SRID=" +
      Config.mapProjCode +
      ";" +
      new WKT().writeFeature(address.olFeature);

    this.props.readFiltered(
      Config.themes.hentedag.columns,
      {
        filterColumns: [filter]
      },
      Config.mapProjCode,
      0,
      1
    );
  }

  onExpand = () => {
    const { fitViewAndZoom } = this.props;
    const { olFeature } = this.state;
    if (olFeature)
      fitViewAndZoom(olFeature.getGeometry(), Config.fitViewAndZoom.in);
  };

  onCollapse = () => {
    const { fitViewAndZoom } = this.props;
    fitViewAndZoom(
      this.props.address.olFeature.getGeometry(),
      Config.fitViewAndZoom.out
    );
  };

  static getDerivedStateFromProps(props, state) {
    if (props.records.length > 0 && !state.olFeature) {
      state.olFeature = new WKT().readFeature(props.records[0].geom_wkt);
    }
    if (props.loading) {
      state.olFeature = undefined;
    }
    return state;
  }

  render() {
    const { t } = this.props;
    const { olFeature } = this.state;

    return (
      <Collapsible
        className="hushald"
        header={t("avvik")}
        onCollapse={this.onCollapse}
        onExpand={this.onExpand}
        toggle={
          <React.Fragment>
            <VectorSource
              features={olFeature ? [olFeature] : []}
              layerName="avvik-layer"
              key="avvik-layer"
              selectable={false}
              zIndex={0}
              styleFn={() =>
                new Style({
                  fill: new Fill({ color: "rgba(71, 139, 180, .1)" }),
                  stroke: new Stroke({ color: "rgb(71,139,180)" })
                })
              }
            />
            <WmsLayer
              name="avvik-wms-layer"
              id="avvik-wms-layer"
              key="avvik-wms-layer"
              layerName="layer_81"
              uri="https://maps.adialog.no/wms.ashx"
              zIndex={-1}
              singleTile
            />
          </React.Fragment>
        }
      >
        <div className="avvik-dropdown--content">
          <h3>{t("registrerte")}</h3>
          <p>{t("intro")}</p>
          <h4>{t("tegnforklaring")}</h4>
          <div className="avvik-content--legend">
            <span className="legend--symbol ubehandlet" />
            <span className="legend--label">{t("ubehandlet")}</span>
          </div>
          <div className="avvik-content--legend">
            <span className="legend--symbol underBehandling" />
            <span className="legend--label">{t("underBehandling")}</span>
          </div>
          <br />
          <Link className="btn" to="/meldingsteneste">
            {t("avvikmeldings")}
          </Link>
        </div>
      </Collapsible>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(provideMapState(translate("AvvikDropdown")(AvvikDropdown)));
