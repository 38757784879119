import React, { useState, useCallback } from "react";
import request from "superagent";
import RadioButtongGroup from "../../component/RadioButtonGroup";
import Config from "../../config/index";
import Loading from "../../component/Loading/Loading";
import { DigiThemeManager, DigiThemeLoader } from "react-adaptive-dt-form";
import { withRouter } from "react-router-dom";
import { translate } from "react-translate";
import Error from "./Error";
import Success from "./Success";
import Attachments from "./Attachments";
import config from "../../config";
import { isInvalid, toRecord } from "../../utils/record";

import "react-adaptive-dt-form/lib/style.scss";

const BestillingSkjema = ({ history, t }) => {
  const [saving, setSaving] = useState();
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [record, setRecord] = useState({});
  const [id, setId] = useState();
  const isRecordInvalid = isInvalid([record]);
  const [attachments, setAttachments] = useState([]);

  const handleField = useCallback(props => {
    const { name } = props;
    switch (name) {
      case "min_soknad_gjeld":
      case "ynskja_hentefrekvens_for_restavfall":
      case "ynskja_dunkstorleik_for_restavfall":
      case "ynskja_dunkstorleik_for_papir_papp":
      case "ynskja_ordning_for_matavfall":
        return <RadioButtongGroup {...props} />;

      case "id":
      case "behandlet":
      case "date_created":
        return false;

      default:
        return;
    }
  }, []);

  const save = useCallback(() => {
    setSaving(true);
    // console.log("save: ", record, attachments);
    request
      .post(Config.adaptiveUrl + "WebServices/client/DataView.asmx/SaveAny")
      .send({
        request: {
          theme_uuid: Config.forms.bestillingSkjema.uuid,
          data: toRecord(record),
          mediaUuids: attachments
        }
      })
      .set("Content-Type", "application/json")
      .then(res => res.body)
      .then(res => {
        if (!res.d.success) {
          console.error("Could not save project", res.d);
          setSaving(false);
          setError(true);
          return;
        }
        setSaving(false);
        setSuccess(true);
        setId(res && res.d.data.length > 0 && res.d.data[0].value.id);
      })
      .catch(e => {
        console.error("Could not save project", e);
        setSaving(false);
        setError(true);
      });
  }, [record, attachments]);

  const onAttachmentAdded = useCallback(
    uuid => {
      setAttachments(a => [...a, uuid]);
    },
    [setAttachments]
  );

  const onAttachmentRemoved = useCallback(
    uuid => {
      setAttachments(att => [att.filter(a => a !== uuid)]);
    },
    [setAttachments]
  );

  //   console.log("attachments", attachments);

  return (
    <>
      <h1>{t("soknadEndring")}</h1>
      {!success && !id && (
        <p className="skjema-header">
          {t("required")} <br />
          <span>{t("sensitive")}</span>
        </p>
      )}
      {!saving && (!success || error) && (
        <DigiThemeManager key="dt-manager" adaptiveUrl={Config.adaptiveUrl}>
          <DigiThemeLoader
            key="dt-loader"
            adaptiveUrl={Config.adaptiveUrl}
            themeUuid={Config.forms.bestillingSkjema.uuid}
            loading={true}
            handleField={handleField}
            onChange={(name, value, invalid, dirty) =>
              setRecord(d => {
                return { ...d, [name]: { value, invalid, dirty } };
              })
            }
            lang={{
              save: t("save"),
              cancel: t("cancel"),
              dateFormat: "dd.MM.yyyy",
              dateFormatPlaceholder: "DD.MM.YYYY"
            }}
          />
          <Attachments
            themeUuid={config.forms.bestillingSkjema.uuid}
            onAttachmentAdded={onAttachmentAdded}
            onAttachmentRemoved={onAttachmentRemoved}
            attachments={attachments}
            label={t("labelVedleggBestilling")}
          />
          <button
            className={["btn", "btn-secondary", "dt-button"].join(" ")}
            type="button"
            onClick={() => history.push("/")}
          >
            {t("cancel")}
          </button>
          <button
            disabled={isRecordInvalid || saving}
            className={["btn", "dt-button"].join(" ")}
            type="button"
            onClick={() => save()}
          >
            {t("save")}
          </button>
        </DigiThemeManager>
      )}
      {!saving && error && (
        <Error key="form-error" onClose={() => setError(false)} />
      )}
      {!saving && success && <Success idx={id} key="form-success" />}
      {saving && (
        <div key="form-loader" className="loader">
          <Loading />
        </div>
      )}
    </>
  );
};

export default translate("Skjema")(withRouter(BestillingSkjema));
