import React, { useState, useCallback, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import useClickedOutside from "../../hooks/useClickedOutside";

import { Link } from "react-router-dom";
import { translate } from "react-translate";

import config from "../../config/index";

import "./Nav.scss";

const Hamburger = () => (
  <span className="hamburger">
    <span />
    <span />
    <span />
  </span>
);

const LinkItem = ({ className, uri, title, onClick }) => {
  return (
    <li className={className ? className : ""} onClick={onClick}>
      <Link to={uri}>{title}</Link>
    </li>
  );
};

const Nav = ({ t, className }) => {
  const isHandheld = useMediaQuery({ query: "(max-width: 767px)" });
  const [open, setOpen] = useState(false);
  const { hushald, hytte, avvik, naering, navigation } = config.nav;
  const ref = useRef();

  const handleClick = useCallback(() => setOpen(!open), [open]);

  useClickedOutside(ref, handleClick);

  return (
    <div className={`menu ${className ? className : ""}`}>
      <button
        className={`btn ${open ? "active" : ""}`}
        type="button"
        onClick={handleClick}
        tabIndex={0}
      >
        <Hamburger />
      </button>

      {open && (
        <nav ref={ref}>
          <div className="flex-container">
            <section className="maincategories">
              <ul>
                <LinkItem
                  uri={hushald.uri}
                  title={hushald.title}
                  onClick={handleClick}
                />

                <LinkItem
                  uri={hytte.uri}
                  title={hytte.title}
                  onClick={handleClick}
                />

                <LinkItem
                  uri={naering.uri}
                  title={naering.title}
                  onClick={handleClick}
                />
                {isHandheld && (
                  <LinkItem
                    className="button"
                    uri={avvik.uri}
                    title={avvik.title}
                    onClick={handleClick}
                  />
                )}
              </ul>
            </section>

            <section className="naering">
              <ul>
                {naering.links.map(item => (
                  <LinkItem
                    key={item.uri}
                    uri={item.uri}
                    title={t(item.title)}
                    onClick={handleClick}
                  />
                ))}
              </ul>
            </section>

            <section className="navigation">
              <ul>
                {navigation.services.map(item => (
                  <LinkItem
                    uri={item.uri}
                    title={item.title}
                    onClick={handleClick}
                    key={item.uri}
                  />
                ))}
              </ul>

              <ul>
                {navigation.questions.map(item => (
                  <LinkItem
                    uri={item.uri}
                    title={item.title}
                    onClick={handleClick}
                    key={item.uri}
                  />
                ))}
              </ul>

              <ul>
                {navigation.us.map(item => (
                  <LinkItem
                    uri={item.uri}
                    title={item.title}
                    onClick={handleClick}
                    key={item.uri}
                  />
                ))}
              </ul>
            </section>
          </div>
        </nav>
      )}
    </div>
  );
};

export default translate("Nav")(Nav);
