const getPath = location => {
  return location.pathname.length > 1 ? location.pathname.slice(1) : "index";
};

const fixLinks = history => {
  let pageContent = document.getElementsByClassName("tell-tur-cms")[0];
  if (pageContent) {
    let links = pageContent.getElementsByTagName("a");
    [].forEach.call(links, link => {
      if (
        link.host !== window.location.host ||
        /\..{3,4}$/.test(link.href) ||
        link.target
      ) {
        return;
      }

      link.onclick = e => {
        let nl = link.pathname;
        e.preventDefault();
        if (link.pathname.indexOf("/") !== 0) {
          nl += "/" + nl;
        }
        history.push(nl);
      };
    });
  }
};

const checkDomainAndPathName = url => {
  var parsedUrl = new URL(url);
  let isCurrentDomain = false;
  if (
    parsedUrl.hostname === "localhost" ||
    parsedUrl.hostname === "ngir.no" ||
    parsedUrl.hostname === "www.ngir.no" ||
    parsedUrl.hostname === "ngir-hjemmeside.vercel.app"
  ) {
    isCurrentDomain = true;
  }
  return { isCurrentDomain, pathname: parsedUrl.pathname };
};

export { getPath, fixLinks, checkDomainAndPathName };
