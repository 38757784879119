import React, { useState, useCallback } from "react";
import request from "superagent";
import RadioButtongGroup from "../../component/RadioButtonGroup";
import { Checkbox } from "@blueprintjs/core";
import Loading from "../../component/Loading/Loading";
import Config from "../../config/index";
import {
  DigiThemeManager,
  DigiThemeLoader,
  DateField,
  Field
} from "react-adaptive-dt-form";
import { withRouter } from "react-router-dom";
import { translate } from "react-translate";
import Error from "./Error";
import Success from "./Success";
import { isInvalid, toRecord } from "../../utils/record";
import Attachments from "./Attachments";
import config from "../../config";
import "./Skjema.scss";
import "react-adaptive-dt-form/lib/style.scss";

const FritakSkjema = ({ history, t }) => {
  const [saving, setSaving] = useState();
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [formState, setFormState] = useState({});
  const [id, setId] = useState();
  const isRecordInvalid = isInvalid([formState]);
  const [attachments, setAttachments] = useState([]);
  const [confirmCorrectInformation, setConfirmCorrectInformation] =
    useState(false);

  const handleField = useCallback(
    props => {
      const { name } = props;
      const { eigedomstype, sokjer_om } = formState;
      switch (name) {
        case "birthdate":
          return (
            <Field {...props}>
              <DateField
                {...props}
                minDate={new Date(1900, 0, 1)}
                maxDate={new Date()}
              />
            </Field>
          );

        case "eigedomstype":
          return <RadioButtongGroup {...props} />;

        case "sokjer_om":
          switch (eigedomstype && eigedomstype.value) {
            case "Fritidsbustad":
              const item = props.items.filter(i => {
                if (i.trim().indexOf("Fritak") > -1) {
                  return true;
                }
                return false;
              });
              //   return <RadioButtongGroup {...props} items={item} />;
              return <></>; // Hide the sokjer_om field for Fritidsbustad
            case "Bustad":
              return <RadioButtongGroup {...props} />;

            default:
              return false;
          }

        case "grunngjeving_for_soknaden":
          switch (sokjer_om && sokjer_om.value) {
            case "Fritak frå gebyr":
              const fritakFraGebyrItem = props.items.filter(i => {
                if (
                  eigedomstype &&
                  eigedomstype.value === "Bustad" &&
                  ["Utleigeeining", "Eigedomen"].some(substring =>
                    i.trim().includes(substring)
                  )
                ) {
                  return true;
                } else if (
                  eigedomstype &&
                  eigedomstype.value === "Fritidsbustad" &&
                  ["Fritidsbustaden ligg", "Eigedomen"].some(substring =>
                    i.trim().includes(substring)
                  )
                ) {
                  return true;
                }
                return false;
              });
              return (
                <RadioButtongGroup {...props} items={fritakFraGebyrItem} />
              );

            case "Halvt gebyr av normalgebyr":
              const halvtGebyrItems = props.items.filter(i => {
                if (
                  ["Søkjer", "Bustaden står", "Bustaden vert nytta som"].some(
                    substring => i.trim().includes(substring)
                  )
                ) {
                  return true;
                }
                return false;
              });
              return <RadioButtongGroup {...props} items={halvtGebyrItems} />;

            case "Levering i sekk":
              const leveringISekkItem = props.items.filter(i => {
                if (i.trim().indexOf("Bustaden ligg") > -1) {
                  return true;
                }
                return false;
              });
              return <RadioButtongGroup {...props} items={leveringISekkItem} />;

            default:
              return false;
          }

        case "id":
        case "behandlet":
        case "date_created":
          return false;

        default:
          return;
      }
    },
    [formState]
  );

  const save = () => {
    setSaving(true);
    request
      .post(Config.adaptiveUrl + "WebServices/client/DataView.asmx/SaveAny")
      .send({
        request: {
          theme_uuid: Config.forms.fritakSkjema.uuid,
          data: toRecord(formState),
          mediaUuids: attachments
        }
      })
      .set("Content-Type", "application/json")
      .then(res => res.body)
      .then(res => {
        if (!res.d.success) {
          console.error("Could not save project", res.d);
          setSaving(false);
          setError(true);
          return;
        }
        setSaving(false);
        setSuccess(true);
        setId(res && res.d.data.length > 0 && res.d.data[0].value.id);
      })
      .catch(e => {
        console.error("Could not save project", e);
        setSaving(false);
        setError(true);
      });
    setSaving(false);
  };

  const onAttachmentAdded = useCallback(
    uuid => {
      setAttachments(a => [...a, uuid]);
    },
    [setAttachments]
  );

  const onAttachmentRemoved = useCallback(
    uuid => {
      setAttachments(att => [att.filter(a => a !== uuid)]);
    },
    [setAttachments]
  );

  return (
    <>
      <h1>{t("soknadDispFritak")}</h1>
      {!success && !id && (
        <p className="skjema-header">
          {t("required")} <br />
          <span>{t("sensitive")}</span>
        </p>
      )}
      {!saving && (!success || error) && (
        <DigiThemeManager key="dt-manager" adaptiveUrl={Config.adaptiveUrl}>
          <DigiThemeLoader
            key="dt-loader"
            adaptiveUrl={Config.adaptiveUrl}
            themeUuid={Config.forms.fritakSkjema.uuid}
            handleField={handleField}
            onChange={(name, value, invalid, dirty) => {
              if (name === "eigedomstype") {
                setFormState(d => {
                  return {
                    ...d,
                    [name]: { value, invalid, dirty },
                    sokjer_om: {
                      value:
                        d.eigedomstype?.value === "Fritidsbustad"
                          ? "Fritak frå gebyr"
                          : undefined,
                      invalid: false,
                      dirty:
                        d.eigedomstype?.value === "Fritidsbustad"
                          ? true
                          : undefined
                    },
                    grunngjeving_for_soknaden: {
                      value: undefined,
                      invalid: true,
                      dirty: undefined
                    }
                  };
                });
                return;
              }

              if (name === "sokjer_om") {
                setFormState(d => {
                  return {
                    ...d,
                    [name]: { value, invalid, dirty },
                    grunngjeving_for_soknaden: {
                      value: undefined,
                      invalid: true,
                      dirty: undefined
                    }
                  };
                });
                return;
              }

              if (name === "birthdate") {
                setFormState(d => {
                  return {
                    ...d,
                    [name]: {
                      value: value ? new Date(value) : undefined,
                      invalid,
                      dirty
                    }
                  };
                });
                return;
              }
              setFormState(d => {
                return { ...d, [name]: { value, invalid, dirty } };
              });
            }}
            formState={formState}
            lang={{
              dateFormat: "dd.MM.yyyy",
              dateFormatPlaceholder: "DD.MM.YYYY",
              months: [
                "Januar",
                "Februar",
                "Mars",
                "April",
                "Mai",
                "Juni",
                "Juli",
                "August",
                "September",
                "Oktober",
                "November",
                "Desember"
              ],
              weekdaysShort: ["Man", "Tir", "Ons", "Tor", "Fre", "Lør", "Søn"],
              weekdaysLong: [
                "Mandag",
                "Tirsdag",
                "Onsdag",
                "Torsdag",
                "Fredag",
                "Lørdag",
                "Søndag"
              ],
              clear: t("clear"),
              today: t("today")
            }}
          />
          <Attachments
            themeUuid={config.forms.fritakSkjema.uuid}
            onAttachmentAdded={onAttachmentAdded}
            onAttachmentRemoved={onAttachmentRemoved}
            attachments={attachments}
            label={t("labelVedleggFritak")}
          />
          <Checkbox
            checked={confirmCorrectInformation}
            large={true}
            onChange={() =>
              setConfirmCorrectInformation(!confirmCorrectInformation)
            }
            label={t("confirmCorrectInformation")}
          />
          <button
            className={["btn", "btn-secondary", "dt-button"].join(" ")}
            type="button"
            onClick={() => history.push("/")}
          >
            {t("cancel")}
          </button>

          <button
            disabled={isRecordInvalid || saving || !confirmCorrectInformation}
            className={["btn", "dt-button"].join(" ")}
            type="button"
            onClick={() => save()}
          >
            {t("save")}
          </button>
        </DigiThemeManager>
      )}
      {!saving && error && (
        <Error key="from-error" onClose={() => setError(false)} />
      )}
      {!saving && success && <Success idx={id} key="form-success" />}
      {saving && (
        <div key="form-loader" className="loader">
          <Loading />
        </div>
      )}
    </>
  );
};

export default translate("Skjema")(withRouter(FritakSkjema));
