import React from "react";
import { Link } from "react-router-dom";
import { translate } from "react-translate";

import bgImg from "../../static/assets/media/imges/landskap.png";

import "./Footer.scss";

const Footer = ({ t }) => (
  <footer>
    <img src={bgImg} alt="footer art" />
    <div className="footer--content">
      <section className="links">
        <Link className="footer--link" to="/hushald/skjema">
          {t("skjema")}
        </Link>

        <Link className="footer--link" to="/omoss">
          {t("omoss")}
        </Link>

        <Link className="footer--link" to="/personvern">
          {t("personvern")}
        </Link>
        <Link className="footer--link" to="/tilgjengelegheitserklaering">
          {t("Tilgjengelighetserklæring")}
        </Link>
        <Link className="footer--link" to="/aapenhetsloven">
          {t("aapenhetsloven")}
        </Link>
        <Link
          style={{ display: "none" }}
          className="footer--link"
          to="/nettstedkart"
        >
          {"Nettstedkart"}
        </Link>
      </section>

      <section className="info">
        <p>
          {t("hushald")}:{" "}
          <a className="tlf" href="tel:+4756343333">
            56 34 33 33
          </a>{" "}
          <a className="mail" href="mailto:mineigedom@ngir.no" target="_top">
            mineigedom@ngir.no
          </a>
        </p>

        <p>
          {t("næring")}:{" "}
          <a className="tlf" href="tel:+4756343320">
            56 34 33 20
          </a>{" "}
          <a className="mail" href="mailto:post@ngir.no" target="_top">
            post@ngir.no
          </a>
        </p>

        <p>
          {t("resepsjon")}:{" "}
          <a className="tlf" href="tel:+4756343310">
            56 34 33 10
          </a>
        </p>
      </section>
    </div>
  </footer>
);

export default translate("Footer")(Footer);
