import React from "react";
import Router from "../routes";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { TranslatorProvider, translate } from "react-translate";
import translations from "../translations";
import {
  Map,
  LayerSwitch,
  LayerGroup,
  WmsLayer,
  Zoom
} from "@avinet/react-openlayers";
import DefaultLoader from "../container/DefaultLoader";
import config from "../config";
import XYZLayer from "../component/XyzLayer";
class App extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  };

  getTranslations(lang) {
    return translations[lang];
  }

  render() {
    const { store } = this.props;
    return (
      <Provider store={store}>
        <TranslatorProvider translations={this.getTranslations("no")}>
          <MapWrapper>
            <DefaultLoader />
            <Router />
          </MapWrapper>
        </TranslatorProvider>
      </Provider>
    );
  }
}

export default App;

const MapWrapper = translate("App")(props => {
  const { t, children } = props;

  return (
    <Map
      minZoom={config.minZoom}
      maxZoom={config.maxZoom}
      extent={config.initExtent}
      track
      baatUrl={config.adaptiveUrl}
      trackTipLabel={t("trackTip")}
      hitTolerance={20}
      projDefs={[
        {
          epsg: "EPSG:32633",
          def: "+proj=utm +zone=33 +ellps=WGS84 +datum=WGS84 +units=m +no_defs"
        }
      ]}
    >
      <Zoom zoomInTipText={t("zoomInTip")} zoomOutTipText={t("zoomOutTip")} />
      <LayerSwitch tooltip={t("kartlagsVelger")}>
        <LayerGroup
          id="basemap_group"
          enabledLayers={["norgeskart_topo4"]}
          name={t("basemapGroup")}
          singleSelect
        >
          <XYZLayer url="https://cache.kartverket.no/v1/wmts/1.0.0/topo/default/webmercator/{z}/{y}/{x}.png" />
        </LayerGroup>
      </LayerSwitch>
      <WmsLayer
        id="world"
        key="world"
        name="Verden"
        uri="https://map-adaptive3.avinet.no/mapserv.ashx?map=public/world&amp;color=226 244 255"
        layerName="ocean_3857"
        version="1.3.0"
        zIndex={-11}
        crossOrigin="Anonymous"
        singleTile
      />
      {children}
    </Map>
  );
});
