import React, { useCallback } from "react";
import { Link } from "react-router-dom";

import sanitizeHtml from "sanitize-html";

import config from "../../config";

import defaultImage from "../../static/assets/media/imges/defaultImage.png";

import "./Card.scss";

const Card = ({ imgSrc, title, description, id, length }) => {
  const truncate = useCallback((str, length = 100) => {
    const ending = "...";

    str = sanitizeHtml(str, {
      allowedTags: ["p"],
      allowedAttributes: {},
      transformTags: {
        h1: "p",
        h2: "p",
        h3: "p",
        h4: "p",
        h5: "p",
        h6: "p"
      }
    });

    if (str.length <= length) return str;

    return sanitizeHtml(str.substring(0, length - ending.length) + ending);
  }, []);

  return (
    <Link to={`/sak/${id}`} className="card">
      <img
        className="card--thumbnail"
        src={
          !imgSrc
            ? defaultImage
            : `${config.adaptiveUrl}WebServices/generic/media.asmx/Download?thumbnail_size=medium&uuid=${imgSrc}`
        }
        alt=""
      />
      <div className="card--content">
        <h3 className="card--content__title">{title}</h3>

        <div
          className="card--content__description"
          dangerouslySetInnerHTML={{
            __html: truncate(description, length)
          }}
        />
      </div>
    </Link>
  );
};

export default Card;
