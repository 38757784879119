import React from "react";
import NavBar from "../container/NavBar/NavBar";
import Footer from "../container/Footer/Footer";
import Sortere from "../component/Search/Sortere";

import { withRouter } from "react-router-dom";

import "./PageLayout.scss";

class PageLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAllHours: false
    };
  }

  checkIfNaering = () => {
    const {
      location: { pathname }
    } = this.props;
    const path = pathname.split("/")[1];

    if (path === "naering") return true;
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState === this.state) {
      this.setState({ showAllHours: false });
    }
  }

  render() {
    const {
      children,
      location: { pathname }
    } = this.props;

    return (
      <div className="page-layout--root">
        <NavBar
          role="navigation"
          onNavBarClick={this.onNavBarClick}
          checkIfNaering={this.checkIfNaering}
        />
        <div className="page-layout--overlay" />
        <main
          className="page-layout--container"
          style={{
            maxWidth:
              pathname !== "/" && pathname !== "/meldingsteneste"
                ? "1000px"
                : "1360px"
          }}
        >
          <div className="page-layout--content">{children}</div>
          <Sortere isNaering={this.checkIfNaering} />
          <Footer role="contentinfo" />
        </main>
      </div>
    );
  }
}

export default withRouter(PageLayout);
