import React from "react";
import PropTypes from "prop-types";
import "./Icon.scss";

const icons = {
  tlf: (
    <svg viewBox="0 0 32 32">
      <path d="M22 20c-2 2-2 4-4 4s-4-2-6-4-4-4-4-6 2-2 4-4-4-8-6-8-6 6-6 6c0 4 4.109 12.109 8 16s12 8 16 8c0 0 6-4 6-6s-6-8-8-6z" />
    </svg>
  ),
  envelope: (
    <svg viewBox="0 0 28 28">
      <path d="M26 23.5v-12c-0.328 0.375-0.688 0.719-1.078 1.031-2.234 1.719-4.484 3.469-6.656 5.281-1.172 0.984-2.625 2.188-4.25 2.188h-0.031c-1.625 0-3.078-1.203-4.25-2.188-2.172-1.813-4.422-3.563-6.656-5.281-0.391-0.313-0.75-0.656-1.078-1.031v12c0 0.266 0.234 0.5 0.5 0.5h23c0.266 0 0.5-0.234 0.5-0.5zM26 7.078c0-0.391 0.094-1.078-0.5-1.078h-23c-0.266 0-0.5 0.234-0.5 0.5 0 1.781 0.891 3.328 2.297 4.438 2.094 1.641 4.188 3.297 6.266 4.953 0.828 0.672 2.328 2.109 3.422 2.109h0.031c1.094 0 2.594-1.437 3.422-2.109 2.078-1.656 4.172-3.313 6.266-4.953 1.016-0.797 2.297-2.531 2.297-3.859zM28 6.5v17c0 1.375-1.125 2.5-2.5 2.5h-23c-1.375 0-2.5-1.125-2.5-2.5v-17c0-1.375 1.125-2.5 2.5-2.5h23c1.375 0 2.5 1.125 2.5 2.5z" />
    </svg>
  ),
  info: (
    <svg viewBox="0 0 16 16">
      <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z" />
      <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z" />
      <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z" />
    </svg>
  ),
  checkmark: (
    <svg viewBox="0 0 32 32">
      <path d="M27 4l-15 15-7-7-5 5 12 12 20-20z" />
    </svg>
  ),
  fileText: (
    <svg viewBox="0 0 32 32">
      <path d="M27 0h-24c-1.65 0-3 1.35-3 3v26c0 1.65 1.35 3 3 3h24c1.65 0 3-1.35 3-3v-26c0-1.65-1.35-3-3-3zM26 28h-22v-24h22v24zM8 14h14v2h-14zM8 18h14v2h-14zM8 22h14v2h-14zM8 10h14v2h-14z" />
    </svg>
  ),
  list: (
    <svg viewBox="0 0 32 32">
      <path d="M12 26h20v4h-20zM12 14h20v4h-20zM12 2h20v4h-20zM6 0v8h-2v-6h-2v-2zM4 16.438v1.563h4v2h-6v-4.563l4-1.875v-1.563h-4v-2h6v4.563zM8 22v10h-6v-2h4v-2h-4v-2h4v-2h-4v-2z" />
    </svg>
  ),
  warn: (
    <svg viewBox="0 0 32 32">
      <path d="M16 2.899l13.409 26.726h-26.819l13.409-26.726zM16 0c-0.69 0-1.379 0.465-1.903 1.395l-13.659 27.222c-1.046 1.86-0.156 3.383 1.978 3.383h27.166c2.134 0 3.025-1.522 1.978-3.383h0l-13.659-27.222c-0.523-0.93-1.213-1.395-1.903-1.395v0z" />
      <path d="M18 26c0 1.105-0.895 2-2 2s-2-0.895-2-2c0-1.105 0.895-2 2-2s2 0.895 2 2z" />
      <path d="M16 22c-1.105 0-2-0.895-2-2v-6c0-1.105 0.895-2 2-2s2 0.895 2 2v6c0 1.105-0.895 2-2 2z" />
    </svg>
  ),
  placeholder: (
    <svg viewBox="0 0 24 24">
      <path d="M9 9v6h6v-6h-6zM6.984 17.016v-10.031h10.031v10.031h-10.031zM15 5.016v-2.016h2.016v2.016h-2.016zM15 21v-2.016h2.016v2.016h-2.016zM18.984 17.016v-2.016h2.016v2.016h-2.016zM18.984 9v-2.016h2.016v2.016h-2.016zM18.984 21v-2.016h2.016c0 1.078-0.938 2.016-2.016 2.016zM18.984 12.984v-1.969h2.016v1.969h-2.016zM11.016 21v-2.016h1.969v2.016h-1.969zM9 3v2.016h-2.016v-2.016h2.016zM3 17.016v-2.016h2.016v2.016h-2.016zM5.016 21c-1.078 0-2.016-0.938-2.016-2.016h2.016v2.016zM18.984 3c1.078 0 2.016 0.938 2.016 2.016h-2.016v-2.016zM12.984 3v2.016h-1.969v-2.016h1.969zM3 9v-2.016h2.016v2.016h-2.016zM6.984 21v-2.016h2.016v2.016h-2.016zM3 12.984v-1.969h2.016v1.969h-2.016zM3 5.016c0-1.078 0.938-2.016 2.016-2.016v2.016h-2.016z" />
    </svg>
  ),
  chevrondown: (
    <svg viewBox="0 0 24 24">
      <path d="M5.293 9.707l6 6c0.391 0.391 1.024 0.391 1.414 0l6-6c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0l-5.293 5.293-5.293-5.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z" />
    </svg>
  ),
  chevronup: (
    <svg viewBox="0 0 24 24">
      <path d="M18.707 14.293l-6-6c-0.391-0.391-1.024-0.391-1.414 0l-6 6c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0l5.293-5.293 5.293 5.293c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414z" />
    </svg>
  ),
  cross: (
    <svg viewBox="0 0 16 16">
      <path d="M13.957 3.457l-1.414-1.414-4.543 4.543-4.543-4.543-1.414 1.414 4.543 4.543-4.543 4.543 1.414 1.414 4.543-4.543 4.543 4.543 1.414-1.414-4.543-4.543z" />
    </svg>
  ),
  attachment: (
    <svg viewBox="0 0 24 24">
      <path d="M16.5 6h1.5v11.484q0 2.297-1.594 3.914t-3.891 1.617-3.914-1.617-1.617-3.914v-12.469q0-1.641 1.195-2.836t2.836-1.195 2.813 1.195 1.172 2.836v10.5q0 1.031-0.727 1.758t-1.758 0.727-1.781-0.727-0.75-1.758v-9.516h1.5v9.516q0 0.422 0.305 0.703t0.727 0.281 0.703-0.281 0.281-0.703v-10.5q0-1.031-0.727-1.781t-1.758-0.75-1.781 0.75-0.75 1.781v12.469q0 1.641 1.195 2.836t2.836 1.195 2.813-1.195 1.172-2.836v-11.484z"></path>
    </svg>
  ),
  trash: (
    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
    </svg>
  ),
  plus: (
    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
    </svg>
  ),
  menu: (
    <svg fill="currentColor" viewBox="0 0 20 20">
      <path
        fill-rule="evenodd"
        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
        clip-rule="evenodd"
      />
    </svg>
  ),
  search: (
    <svg viewBox="0 0 20 20" fill="currentColor">
      <path
        fillRule="evenodd"
        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
        clipRule="evenodd"
      />
    </svg>
  )
};

const Icon = props => {
  const { name } = props;
  return <span className="icon">{icons[name]}</span>;
};

Icon.propTypes = {
  name: PropTypes.string.isRequired
};

export default Icon;
