import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
import { Link } from "react-router-dom";
import radgjeving from "../static/info_ikon.svg";
import farligavfall from "../static/farlig.svg";
import deponi from "../static/urenemasser.svg";
import steinsand from "../static/steinsand.svg";
import konteinerleie from "../static/container.svg";
import mottakskrav from "../static/checklist_ikon.svg";
import "./NaeringView.scss";

class NaeringView extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div className="naering-view--root">
        <div className="naering-view--container">
          <div className="naering-view--flex-container">
            <div className="naering-view--intro-text">
              <div>
                Her finn du prisar og informasjon om avfall som du kan levere
                inn til oss som næringsdrivende. Treng du rådgjeving? Send
                e-post til <a href="mailto:post@ngir.no">post@ngir.no</a> eller
                ring <a href="tel:004756343320">56 34 33 20</a>.
              </div>
            </div>
            <div className="naering-view--flex-item">
              <Link to="/naering/radgjeving">
                <img src={radgjeving} alt="Farlig avfall" />
                {t("radgjeving")}
              </Link>
            </div>
            <div className="naering-view--flex-item">
              <Link to="/leigekonteinar">
                <img src={konteinerleie} alt="Konteinerleie" />
                {t("konteinarleige")}
              </Link>
            </div>
            <div className="naering-view--flex-item">
              <Link to="/steinsandoggrus">
                <img src={steinsand} alt="Stein, grus og pukk" />
                {t("steinsandgrus")}
              </Link>
            </div>
            <div className="naering-view--flex-item">
              <Link to="/naering/farlegavfall">
                <img src={farligavfall} alt="Farlig avfall" />
                {t("farligavfall")}
              </Link>
            </div>
            <div className="naering-view--flex-item">
              <Link to="/naering/deponi">
                <img src={deponi} alt="Deponi" />
                {t("deponi")}
              </Link>
            </div>
            <div className="naering-view--flex-item">
              <Link to="/naering/mottakskrav">
                <img src={mottakskrav} alt="Levering av avfall" />
                {t("mottakskrav")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NaeringView.propTypes = {
  t: PropTypes.func.isRequired
};

export default translate("NaeringView")(NaeringView);
